import React from "react";
import css from "./ToastContainer.module.scss";
import {DefaultToastContainer} from 'react-toast-notifications';

const ToastContainer = ({...props}) => {
  return (<DefaultToastContainer {...props} className={css.toast_container}>
    {props.children}
  </DefaultToastContainer>);
};

export default ToastContainer;
