// import * as Sentry from '@sentry/browser';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from 'next/app';
import React from 'react';
import { ToastProvider } from 'react-toast-notifications';
import GTMService from "services/utils/GTMService";
import Toast from "shared/toast/Toast";
import ToastContainer from "shared/toast/ToastContainer";
import { appWithTranslation } from '../i18n';
import Head from "next/head";
import '../static/styles/App.scss';

const packageJson = require('../../package.json');

// Sentry.init({
//   dsn: process.env.REACT_SENTRY_DSN,
//   environment: process.env.NODE_ENV,
//   release: packageJson.version,
//   debug: true,
//   attachStacktrace: true
// });

class MyApp extends App {
  componentDidMount() {
    GTMService.initialize();
  }

  componentDidCatch(error, errorInfo) {
    console.log("errors here:", error);
    // Sentry.withScope((scope) => {
    //   Object.keys(errorInfo).forEach((key) => {
    //     scope.setExtra(key, errorInfo[key]);
    //   });
    //
    //   Sentry.captureException(error);
    // });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
    <React.Fragment>
    <Head>
    <meta name="google-site-verification" content={process.env.REACT_APP_GOOGLE_SITE_VERIFICATION} />
    </Head>
    <GoogleOAuthProvider clientId={process.env.REACT_GOOGLE_APP_ID}>
      <ToastProvider components={{ Toast: Toast, ToastContainer: ToastContainer }}>
        <Component {...pageProps} />
      </ToastProvider>
    </GoogleOAuthProvider>
    </React.Fragment>
    
    );
  }
}

export default appWithTranslation(MyApp);
