import TagManager from 'react-gtm-module';

const self = {};

self.initialize = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_GOOGLE_TAG_MANAGER_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

self.sendEvent = (eventName) => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName
    },
  });
}

export default self;
