const NextI18Next = require('next-i18next/dist/commonjs').default;

const options = {
  otherLanguages: ['fr'], defaultLanguage: 'fr', fallbackLng: 'fr',

  defaultNS: 'common', fallbackNS: 'common',

  localePath: 'src/static/i18n',

  initImmediate: false,

  interpolation: {escapeValue: false},

  /*
  detection: {
     cookieSecure: process.env.NODE_ENV !== "development"
  },

  */
};

module.exports = new NextI18Next(options);
