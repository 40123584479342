import React from "react";
import css from "./Toast.module.scss";
import {DefaultToast} from 'react-toast-notifications';

const Toast = ({...props}) => {
  return (<DefaultToast {...props} className={`${css.toast} ${css['toast__' + props.appearance]}`}>

    <button type="button"
            onClick={() => props.onDismiss()}
            className={css.toast__close_button}
    >
      <img srcSet={'/static/images/pictos/icon-close-black.svg'}/>
    </button>
    {props.children}
  </DefaultToast>);
};

export default Toast;
